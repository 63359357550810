import { gql } from '@apollo/client/core';

export const AdminListClientsQuery = gql`
  query AdminListClients {
    clients {
      id
      name
      slug
      firebase_id
    }
  }
`;

export const AdminCreateClientMutation = gql`
  mutation AdminCreateClient($object: clients_insert_input!) {
    insert_clients_one(object: $object) {
      id
    }
  }
`;

export const AdminClientQuery = gql`
  query AdminClientQuery($id: Int!) {
    clients_by_pk(id: $id) {
      id
      name
      sites {
        id
        name
        production_hours {
          day_id
          end
          id
          start
        }
        production_start_weekday
      }
      users {
        id
        last_name
        first_name
        email
        client_id
        role
        weekly_reporting
      }
    }
  }
`;

export const AdminUpdateClientMutation = gql`
  mutation AdminUpdateClient($id: Int!, $object: clients_set_input!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      name
    }
  }
`;
