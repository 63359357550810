import { AdminListClientsQuery, AdminClientQuery, AdminUpdateClientMutation, AdminCreateClientMutation } from '../../graphql/client';
import type { AdminApiGetClient, AdminApiGetClients } from '../../types';

export const useClientApi = () => {
  // Composables
  const { apolloClient } = useApollo();
  const { t } = useI18n();

  // Store
  const { setCurrentClient } = adminStore();
  const { showToast } = toastStore();

  // ========================================================== Client ==========================================================
  const getClients = async () => {
    const response = await apolloClient.query<AdminApiGetClients>({ query: AdminListClientsQuery });
    return response.data.clients;
  };

  const createClient = async (data: Partial<AdminApiGetClient['clients_by_pk']>) => {
    try {
      await apolloClient.mutate({ mutation: AdminCreateClientMutation, variables: { object: data } });
    } catch (error) {
      showToast({ title: t('global.error'), message: t('admin.client_create_error'), type: 'error' });
      throw error;
    }
  };

  const getClient = async (id: number) => {
    const response = await apolloClient.query<AdminApiGetClient>({ query: AdminClientQuery, variables: { id } });
    setCurrentClient(response.data.clients_by_pk);
    return response.data.clients_by_pk;
  };

  const updateClient = async (id: number, data: Partial<AdminApiGetClient['clients_by_pk']>) => {
    try {
      await apolloClient.mutate({ mutation: AdminUpdateClientMutation, variables: { id, object: data } });
      const response = await getClient(id);
      await setCurrentClient(response);
      showToast({ title: t('global.success'), message: t('admin.client_updated'), type: 'success' });
    } catch (error) {
      showToast({ title: t('global.error'), message: t('admin.client_update_error'), type: 'error' });
      throw error;
    }
  };

  return { getClients, getClient, updateClient, createClient };
};
